import * as TYPES from './types';

const translations = (state = {}, action) => {
  let payload;

  switch (action.type) {
    case TYPES.FETCH_TRANSLATIONS_SUCCESS:
      payload = action.payload;
      return {
        loaded: true,
        data: payload,
      };
  }

  return state;
};

const initialAppState = {
  organisations: [
    'Aangenaam ByMenno',
    'Amaris',
    'Careyn',
    'De Haven',
    'De Koperhorst',
    'De Wulverhorst',
    'Groot Hoogwaak',
    'Holland Zorggroep',
    'Jah-Jireh Woonzorg',
    'King Arthur Groep',
    'Maria Dommer',
    'Moriahoeve',
    'Noorderzorg',
    'Pieters en Bloklands',
    'Pro Senectute',
    'QuaRijn',
    'Roomburgh',
    'Topaz',
    'Vredenoord',
    'Warande',
    'WIJdezorg',
    'Zorgcooperatie Accent: Zorggroep Marijke',
    'Zorg- en Wooncentrum De Haven',
    'Zorgvilla In de Luwte',
    'ZZP-/individueel lidmaatschap',
    'Overig',
  ],
};

const app = (state = initialAppState, action) => {
  switch (action.type) {
    case TYPES.FETCH_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        ready: true,
      };
  }

  return state;
};

export default {
  app,
  translations,
};
